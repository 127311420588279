import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    var element = document.querySelector('.header-area');
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('is-sticky');
    } else {
      element.classList.remove('is-sticky');
    }
  }
}
