import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProductComponent } from './product/product.component';
import { FollowUsComponent } from './shared/follow-us/follow-us.component';
import { TermsComponent } from './terms/terms.component';


const routes: Routes = [
  {
    path: '',
    pathMatch : 'full',
    redirectTo: 'home'
  },
  {path:'home',component:HomeComponent},
  {path:'product/:id',component:ProductComponent},
  {path:'follow-us',component:FollowUsComponent},
  {path:'privacy',component:PrivacyComponent},
  {path:'terms',component:TermsComponent},
  {path:'about',component:AboutComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
