import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  data
  constructor(private service:AppService) { }
  ngOnInit(): void {
    this.service.generalText('terms').subscribe((res:any)=>{
      this.data = res.data
    })
  }

}
