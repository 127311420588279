import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  data
  constructor(private service:AppService) { }
  ngOnInit(): void {
    this.service.generalText('about_app').subscribe((res:any)=>{
      this.data = res.data
    })
  }

}
