import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProductComponent } from './product/product.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxViewerModule } from 'ngx-viewer';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './helpers/timeout.interceptor';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { AboutComponent } from './about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductComponent,
    PrivacyComponent,
    TermsComponent,
    AboutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    CarouselModule,
    NgxViewerModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule
  ],
  providers: [
    // [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    // [{ provide: DEFAULT_TIMEOUT, useValue: 30000 }]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
