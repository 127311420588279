import { AppService } from './../app.service';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import AOS from 'aos';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';
//

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  products = {
    loading: true,
    data: null
  }
  categories;
  selectedCategory;
  @ViewChild('searchInput') yourElement: ElementRef;

  constructor(private service:AppService,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    AOS.init();
    this.getHomeApi()
    this.getCategories()

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Close ');
  }

  getHomeApi(page = 1){
    this.products.loading = true
    this.service.home(page).pipe(map(res=>res['data'])).subscribe(res=>{
      if(page == 1){
        this.products.data = res
      }else{
        this.products.data.current_page = page
        this.products.data.data = [...this.products.data.data, ...res.data]
      }
      this.products.loading = false
    })
  }
  filter = {
    key: null,
    value: null
  }
  productsFilters(key,value,page = 1){
    this.filter.key = key
    this.filter.value = value

    this.products.loading = true
    this.service.prodcutsFilters(key,value).pipe(map(res=>res['data'])).subscribe(res=>{
      if(page == 1){
        this.products.data = res
      }else{
        this.products.data.current_page = page
        this.products.data.data = [...this.products.data.data, ...res.data]
      }
      this.products.loading = false
    })
  }
  postsFilters(key,value,page = 1){
    // this.selectedCategoryId = value
    this.filter.key = key
    this.filter.value = value

    this.products.loading = true
    this.products.data = null
    this.service.postsFilters(key,value).pipe(map(res=>res['data'])).subscribe(res=>{
      if(page == 1){
        this.products.data = res
      }else{
        this.products.data.current_page = page
        this.products.data.data = [...this.products.data.data, ...res.data]
      }
      this.products.loading = false
    })
  }
  getCategories(){
    this.service.categories().pipe(map(res=>res['data'])).subscribe(res=>{
      console.log(res)
      this.categories = res
    })
  }
  ngAfterViewInit(): void {
    fromEvent(this.yourElement.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(700))
      .pipe(distinctUntilChanged())
      .subscribe(text => {
        this.selectedCategory = null

        if(!text){
          // this.results = []
          this.getHomeApi()
          return 0
        }

        this.productsFilters('search_text',text)
        // this.loading = true
        // this.location.getLocationNameByText(text).pipe(map(response=>response['features'])).subscribe(features=>{
        //   this.results = features
        //   this.loading = false
        // })
      });
  }
  // bookmarkInLocalStorage(product){
  //   let bookmarks:any = localStorage.getItem('bookmarks')
  //   if(bookmarks){
  //     bookmarks = JSON.parse(bookmarks)
  //     if(bookmarks.includes(product.id)){
  //       bookmarks = bookmarks.filter(item=>item != product.id)
  //       localStorage.setItem('bookmarks',JSON.stringify(bookmarks))
  //       product.bookmarked = false
  //     }else{
  //       bookmarks.push(product.id)
  //       localStorage.setItem('bookmarks',JSON.stringify(bookmarks))
  //       product.bookmarked = true
  //     }
  //   }else{
  //     localStorage.setItem('bookmarks',JSON.stringify([product.id]))
  //     product.bookmarked = true
  //   }
  // }
  // storeToBookmarks(product){
  //   var bookmarks :any = JSON.parse(localStorage.getItem('bookmarks'));

  //   if(bookmarks){
  //     bookmarks.forEach(element => {
  //       if(element.id == product.id){
  //         bookmarks = bookmarks.filter(item=>item.id != product.id)
  //         localStorage.setItem('bookmarks',JSON.stringify(bookmarks))
  //         product.bookmarked = false
  //         return 0
  //       }else{
  //         bookmarks.push(product)
  //         localStorage.setItem('bookmarks',JSON.stringify(bookmarks))
  //         product.bookmarked = true
  //       }
  //     });
  //   }else{
  //     localStorage.setItem('bookmarks',JSON.stringify([product]))
  //     product.bookmarked = true
  //   }
  //   // localStorage.setItem('bookmarks',JSON.stringify([product.id]))
  // }
}
