import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http:HttpClient) { }
  home(page = 1){
    return this.http.get(`${environment.endPoint}/home/posts?with_paginate=yes&page=${page}`)
  }
  postsFilters(param,value,page = 1){
    let base = new URL(`${environment.endPoint}/home/posts?with_paginate=yes&page=${page}`);
    base.searchParams.append(param, value);
    return this.http.get(base.href)
  }
  prodcutsFilters(param,value,page = 1){
    let base = new URL(`${environment.endPoint}/public/products/search?page=${page}`);
    base.searchParams.append(param, value);
    return this.http.get(base.href)
  }
  showProduct(id){
    return this.http.get(`${environment.endPoint}/product/show?item_id=${id}`)
  }
  categories(){
    return this.http.get(`${environment.endPoint}/categoires?with_paginate=no`)
  }
  generalText(key){
    return this.http.get(`${environment.endPoint}/general_text?key_text=${key}`)
  }
}
