import { AppService } from './../app.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import Viewer
import Viewer from 'viewerjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit {
  images = []
  imagesHtmlCodes
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    slideTransition: 'linear',
    autoplayTimeout: 20,
    autoplaySpeed: 30000,
    autoplayHoverPause: false,
    margin: 25,
    items: 1,
    nav: false
  }
  url = window.location.href;
  public id = this.route.snapshot.paramMap.get('id')
  product

  constructor(private service:AppService,public route: ActivatedRoute,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.show()
  }


  openSnackBar(message: string) {
    this._snackBar.open(message, 'Close ');
  }
  show(id = this.id){
    this.product = null
    this.service.showProduct(id).pipe(map(res=>res['data'])).subscribe((res)=>{
      console.log(res)
      this.product =res
      this.product.item.item_media.forEach((image)=>{
        image.path = image.full_path
      })
      this.images = this.product.item.item_media
      this.imagesToHtmlElements()

    })
  }
   parseHTML(html) {
    var t = document.createElement('template');
    t.innerHTML = html;
    return t.content;
}
  // images to code
  // imagesStracture(){
  //   return this.images.map((image)=>{
  //     return `<img src="${image.path}" alt="Image">`
  //   }).join('')
  // }

  // 3 images in a group
  imagesGroup(){
    let images = this.images
    let imagesGroup = []
    for (let i = 0; i < images.length; i+=3) {
      imagesGroup.push(images.slice(i, i+3))
    }
    return imagesGroup
  }

  imagesToHtmlElements(){
    this.imagesHtmlCodes =  this.images.map((image)=>{
      document.createElement("img").setAttribute("src", image.path);
      return `<img src="${image.path}" alt="Image">`
    }).join('')
    this.images = this.imagesGroup()
  }



  viewImage(src){
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = this.imagesHtmlCodes;
    // change chlidnodes order to show the selected image first
    var selectedImage = htmlObject.querySelector(`img[src="${src}"]`);
    htmlObject.insertBefore(selectedImage, htmlObject.firstChild);
    htmlObject.innerHTML = htmlObject.innerHTML
    const gallery = new Viewer(htmlObject);
    gallery.show();
  }
  getWatsappLink(){
    function HasArabicCharacters(text)
      {

          var regex = new RegExp("[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]");
          return regex.test(text);
      }
    let country_code = this.product.item.country_code,
        phone_number = this.product.item.phone_number,
        name = this.product.item.name,
        user_name = this.product.item.owner.name,
        message = `Hello ${user_name}, I'm interested in your product ${name}.`,
        ar_message = `مرحبا ${user_name}, أنا مهتم بمنتجك ${name}.`;
    return `https://wa.me/${country_code}${phone_number}?text=${HasArabicCharacters(message) ? ar_message : message}`
  }
}
