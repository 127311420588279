import { AppService } from './../app.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  data
  constructor(private service:AppService) { }
  ngOnInit(): void {
    this.service.generalText('privacy_policy').subscribe((res:any)=>{
      console.log(res.data)
      this.data = res.data
    })
  }

}
